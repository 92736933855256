
.overlay {
  width: 200px;
  height: 200px;
  position: absolute;
  background-color: lightgray;        
  opacity: 0;
  z-index: 1;
}

.overlay i {
  font-size: 2em;
  opacity: 1;
  color: #fff;
  margin-top: 35%;
  margin-left: 35%;
}

.overlay:hover {
  opacity: 0.8;  
}

.button {
  display: inline-block;
  padding: 0.5rem;
  background: #EEE;
  border: none;
  border-radius: 7px;
  font-size: small;
  text-shadow: 0 1px white;
  cursor: pointer;
}

.button:hover {
  background-color: rgb(231, 226, 224);
  text-shadow: 0 1px hsla(0, 0%, 100%, 0.5);
  color: #222;
}

.button:active,
.button.is-checked {
  background-color: #28F;
}

.button.is-checked {
  color: white;
  text-shadow: 0 -1px hsla(0, 0%, 0%, 0.8);
}

.button:active {
  box-shadow: inset 0 1px 10px hsla(0, 0%, 0%, 0.8);
}

/* ---- button-group ---- */

.button-group {
  margin-bottom: 2%;
  text-align: center;
}

.button-group:after {
  content: '';
  display: block;
  clear: both;
}

.button-group .button {
  border-radius: 0;
  margin-left: 0;
  margin-right: 1px;
}

.button-group .button:first-child { border-radius: 0.5em 0 0 0.5em; }
.button-group .button:last-child { border-radius: 0 0.5em 0.5em 0; }

/* ---- isotope ---- */

.grid {
  border: 0px solid #333;
}

/* clear fix */
.grid:after {
  content: '';
  display: block;
  clear: both;
}

/* ---- .element-item ---- */
.element-item {
    position: relative;
    float: left;
    /* width: 200px;
    height: 200px; */
    margin: 10px;
    padding: 0px;
    /* background: #888; */
    color: #262524;
  }

.element-item img {
  position: relative;
  float: left;
  width: 200px;
  height: 200px;
  background: #888;
  color: #262524;
}

