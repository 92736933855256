form {
    width: 95%;
    margin: auto;
}

@media only screen and (min-width: 800px) {
    form {
        width: 80%;
        margin: auto;
    }
  }

.form-group {
    margin: 3% 0 0 0;

}