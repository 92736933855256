.skill-container {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: space-around;
    /* column-gap: 5%; */
    width: 65%;
    margin: auto;
}

.skill {
    display:inline-block;   
    margin-bottom: 15%;
    opacity: 0.8;
    margin-left: 18%;
    /* -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    /* -moz-animation: fadein 1s; /* Firefox < 16 */
    /* -ms-animation: fadein 1s; Internet Explorer */
    /* -o-animation: fadein 1s; Opera < 12.1 */
    /* animation: fadein 1s; */ 
}

.skill img {
   width: 200px;
   height: 200px;
}

.skill span {
    display:block;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
}

.skill:hover {
    background-color: rgba(0,0,0,.03);
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 30%);
    opacity: 1;    
    /* zoom: 1.1; */
    cursor: pointer;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}