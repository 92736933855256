html > * {
  font-size: 1.2em;
  box-sizing: border-box;
  display: block;
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
}

a {
  color: #176ceb;
  color:#141619;
  text-decoration: underline;
}

a:hover, a:focus {
  color: #176ceb
}

body {  
  position: relative;
  font-family: 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family:  'Muli', sans, arial;    
   color: #888888;
   color: #757575;   
   background-color: #fafafa;
}

.header {
  width: 100%;
  padding: 2%; 
  background-color: #fff;   
  min-height: 8vh; 
}

header > h1 {
  color:#ccc; 
}

header .tagline {
  text-align: center;
  font-size: 3vw;
  color: #ccc;
  letter-spacing: 7px;
  font-weight: bold;
  margin-top: 0%;
  display:none;
}

@media only screen and (min-width: 769px) {
  header .tagline { 
    display: block;
  }
}

main { 
  display: block;
  padding: 0 0% 2% 0% ;
  background-color:#fafafa;    
  width: 100%; 
  min-height: 48vh; 
}

footer {
  position: relative;  
  padding: 0em;
  text-align: center;
  border-top:1px solid #eee
}

.section-title {
  font-size: 2rem;
  letter-spacing: 5px;  
  letter-spacing: -0.02em;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  text-align: center;   
  padding: 20px 0;  
}

.copy {  
  border-top:1px solid #eee;
  background-color: #555;
  color: white;
}

div#logo {
  display:inline-block;
  vertical-align: top;
}

.app-logo {
  width: 3.5rem;
  height: 3.5rem;
}

#quote {
  display: block;
  margin-top: 0%;
  color: #555;
  background-color: #eee;  
  padding: 3%;
}

.spacer {
  display:block;
  width: 100px;
  height: 2px;
  margin: auto;
  background-color: #888;
  margin-bottom: 2%;
}

#page-contact {
  background: #eee;
  padding: 2rem 0;
}

#page-contact a:hover {
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  color: red;
}

.contact-info {
  margin: 0 auto;
  text-align: center;
}

/* social media buttons */
.btn-round {
  border-radius: 50%;
  width: 50px;
  height: 50px; 
  font-size: 20px;  
  display: inline-block;
  color: #888;
  border: 1px solid #fff;
  margin-left: 2%;
}
.btn-round:hover {
  background-color: #fff;
  color: red;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}

.home-image {
  width: 100%;  
  height: 325px;  
 /* filter: blur(1.5px);
-webkit-filter: blur(1.5px);  */
}

.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
}
@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

@-ms-keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}



